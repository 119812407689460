
import { Options, Vue } from 'vue-class-component';
import { DxColumn, DxLookup, DxMasterDetail } from 'devextreme-vue/data-grid';
import ODataStore from 'devextreme/data/odata/store';
import { Container } from 'typescript-ioc';
import { AlertService, IAlert, SmartTableV2, Titlebar } from 'rey-web-toolkit';
import SystemInsightSettingsEditDialog from './SystemInsightSettingsEditDialog.vue';
import WorkflowExecutionStrategy from '@/models/SystemInsight/WorkflowExecutionStrategy';
import SystemInsightMessage from '@/models/SystemInsight/SystemInsightMessage';
import SystemInsightEventWorkflow from '@/models/SystemInsight/SystemInsightEventWorkflow';
import axios from 'axios';
import { Options as DataSourceOptions } from 'devextreme/data/data_source';

@Options({
  components: {
    DxColumn,
    DxLookup,
    DxMasterDetail,
    SmartTableV2,
    SystemInsightSettingsEditDialog,
    Titlebar,
  },
})
export default class WorkerWorkplace extends Vue {
  private alertService = Container.get(AlertService);

  public dataStore: DataSourceOptions<SystemInsightMessage, string> | undefined = undefined;
  public isSystemInsightSettingsEditModeActive = false;
  public isWorkInProgress = false;

  public logLevels = [
    { key: 'Trace', name: 'Trace' },
    { key: 'Debug', name: 'Debug' },
    { key: 'Information', name: 'Information' },
    { key: 'Warning', name: 'Warning' },
    { key: 'Error', name: 'Error' },
    { key: 'Critical', name: 'Critical' },
  ];

  public async beforeCreate() {
    document.title = 'SystemInsight';

    this.dataStore = {
      store: new ODataStore<SystemInsightMessage, string>({
        url: process.env.BASE_URL + 'odata/SystemInsightMessage',
        key: 'key',
        version: 4,
      }),
      expand: ['systemInsightEvent.plugin', 'systemInsightEvent.workflows.workflowDefinition'],
    };
  }

  public toBadgeColor(logLevel: string): string {
    switch (logLevel.toLowerCase()) {
      case 'trace':
      case 'debug':
      case 'information':
        return 'info';
      case 'warning':
        return 'warning';
      case 'error':
      case 'critical':
        return 'danger';
    }
    return 'danger';
  }

  public handleErrorWhileOpeningEditSettingsDialog() {
    this.alertService.add({ type: 'danger', message: 'Der Dialog konnte nicht geöffnet werden.', autoCloseInMs: 5000 });
    this.isSystemInsightSettingsEditModeActive = false;
  }

  public handleSettingsSuccessfullySaved() {
    this.alertService.add({ type: 'success', message: 'Die Einstellungen wurden erfolgreich gespeichert.', autoCloseInMs: 3000 });
    this.isSystemInsightSettingsEditModeActive = false;
  }

  public handleEditSettingsDialogCancelled() {
    this.isSystemInsightSettingsEditModeActive = false;
  }

  public getFormattedData(data: string) {
    try {
      return JSON.stringify(JSON.parse(data), null, 2);
    } catch (error) {
      return data;
    }
  }

  public async executeWorkflowsForEvent(systemInsightMessage?: SystemInsightMessage) {
    if (systemInsightMessage === undefined) return;
    if (this.isWorkInProgress) return;

    this.isWorkInProgress = true;
    try {
      return await axios.post('/api/SystemInsightMessage/' + systemInsightMessage.key + '/ExecuteAssociatedWorkflows');
    } finally {
      (this.$refs['systemInsightTableRef'] as any)?.$refs?.dxDataGrid?.instance?.getDataSource()?.reload();
      this.isWorkInProgress = false;
    }
  }

  public isWorkflowExecutionPossible(systemInsightMessage?: SystemInsightMessage): boolean {
    return this.getManualExecutionWorkflows(systemInsightMessage).some(() => true);
  }

  public getManualExecutionWorkflows(systemInsightMessage?: SystemInsightMessage): SystemInsightEventWorkflow[] {
    if (systemInsightMessage === undefined) {
      return [];
    }
    if (systemInsightMessage?.systemInsightEvent?.workflows === null) {
      return [];
    }
    if (systemInsightMessage?.systemInsightEvent?.workflows === undefined) {
      return [];
    }

    if (systemInsightMessage.numberOfManualWorkflowExecutions <= 0) {
      return systemInsightMessage.systemInsightEvent.workflows.filter(
        (x) =>
          x.executionStrategy === WorkflowExecutionStrategy.ManualExecutionOnce ||
          x.executionStrategy === WorkflowExecutionStrategy.ManualExecutionMultipleTimes,
      );
    }

    return systemInsightMessage.systemInsightEvent.workflows.filter(
      (x) => x.executionStrategy === WorkflowExecutionStrategy.ManualExecutionMultipleTimes,
    );
  }
}
